import Swiper from 'swiper';
import { Navigation } from 'swiper/modules';

const swiperHero = document.querySelector('[data-swiper="hero"]');
const swiperButtonPrev = document.querySelector('[data-swiper-button-prev="hero"]');
const swiperButtonNext = document.querySelector('[data-swiper-button-next="hero"]');


const initSwiperHero = () => {
  new Swiper(swiperHero, {

    // Параметры
    direction: 'horizontal',
    loop: true,
    effect: 'slider',
    slidesPerView: 1,
    modules: [Navigation],
    // Кнопки
    navigation: {
      nextEl: swiperButtonNext,
      prevEl: swiperButtonPrev,
    },

    breakpoints: {
      320: {
        slidesPerView: 1, spaceBetween: 20,
      },

      768: {
        slidesPerView: 1, spaceBetween: 25,
      },

      1440: {
        slidesPerView: 1, spaceBetween: 18,
      },
    },
  });
}

export { initSwiperHero };
