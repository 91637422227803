import { iosVhFix } from './utils/ios-vh-fix';
import { initSwiperHero } from "./modules/hero-swiper.js";

window.addEventListener('DOMContentLoaded', () => {
  initSwiperHero();

  window.addEventListener('load', () => {
    iosVhFix();
  });
});
